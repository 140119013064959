
export function smeUI(location) {
  return hashPrefix(location, 'sme');
}

export function smeNavbar(location) {
  return !noAccess(location);
}

export function noAccess(location) {
  return hashPrefix(location, 'sidious');
}

/*
 * Use this function to check that the hash begins with a certain prefix
 */
function hashPrefix(location, ...prefixes) {
  return prefixes.some(prefix => location.hash.indexOf(`#/${prefix}`) === 0);
}

function hashContains(location, seg) {
  return location.hash.indexOf(seg) > -1;
}