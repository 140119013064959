import { asyncStacktrace } from 'auto-trace';
import { property } from 'lodash';

export default function() {
  return Promise.all([SystemJS.import('canopy-urls!sofe'), SystemJS.import('fetcher!sofe')]).then(values => {
    //eslint-disable-line
    const canopyUrls = values[0].default;
    const fetcher = values[1].default;

    return fetcher(`${canopyUrls.getAPIUrl()}/tenants/0`)
      .then(response => {
        if (response.ok) {
          return response
            .json()
            .then(property('tenants'))
            .then(tenant => (window.tenant = tenant));
          // .catch errors are handled by spa-bootstrapper
        } else if (response.status === 403) {
          return 'Not a real tenant -- server responded with forbidden';
        } else {
          // This error message will be wrapped below to be more detailed
          throw new Error(`server responded with status ${response.status}`);
        }
      })
      .catch(
        asyncStacktrace(err => {
          // This will bubble up all the way to spa-bootstrapper
          err.message = `Error retrieving logged in tenant -- '${err.message}'`;
          throw err;
        })
      );
  });
  // .catch Errors are caught by spa-bootstrapper
}
