export default function(loggedInUser) {
  const { hash } = window.location;

  if (!hash || hash === "#" || hash === "#/") {
    window.location.hash = "#/sme";
  }

  return loggedInUser.then(user => {
    if (!user.permissions.sme) {
      window.location.hash = "#/sidious";
    }
  });
}
