export default function() {
  return Promise.resolve({
    bootstrap: () => Promise.resolve(),

    mount: () =>
      SystemJS.import('canopy-urls!sofe').then(urls => {
        document.getElementById('cp-main-content').innerHTML = `
          <div style="display: flex; flex-direction: column; text-align: center; margin-top: 100px;">
            <div>
              <img style="width: 630px;" src='https://cdn.canopytax.com/sofe/sme-ui/sidious.jpg'>
            </div>
            <div>
              <h2>Your feeble skills are no match for the power of the dark side.</h2>
              <a href="${urls.default.getAuthUrl()}/#/logout">Sign out</a>
            </div>
          </div>
          `;
      }),

    unmount: () => {
      document.getElementById('cp-main-content').innerHTML = '';
      return Promise.resolve();
    },
  });
}
