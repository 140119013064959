import sentryBootstrap from "./sentry.bootstrap.js";
import rootAppBootstrap from "./root-app.bootstrap.js";
import loggedInUserBootstrap from "./logged-in-user.bootstrap.js";
import tenantBootstrap from "./tenant.bootstrap.js";
import sofeInspectorBootstrap from "./sofe-inspector.bootstrap.js";

import lodash from "lodash";
import { catchSyncStacktrace, syncStacktrace } from "auto-trace";

window._ = lodash;

export default function bootstrapSpa(callback) {
  // Very first thing is to make sure we talk to the correct backends
  const loggedInUserPromise = loggedInUserBootstrap();
  const tenantPromise = tenantBootstrap();
  let appFailure = false;

  const blockingPromises = [
    loggedInUserPromise,
    tenantPromise,
    rootAppBootstrap(loggedInUserPromise),
  ];

  const nonBlockingPromises = [
    sentryBootstrap(loggedInUserPromise),
    sofeInspectorBootstrap(),
  ];

  Promise.all(nonBlockingPromises)
    .then(() => {
      console.info(
        "The non-blocking bootstrap process for sme-root-config has completed"
      );
    })
    .catch(err => {
      if (appFailure) return; // Error is already reported

      nonBlockingPromises[0] // Make sure sentry is initialized
        .then(() => catchSyncStacktrace(err))
        .catch(() => catchSyncStacktrace(err)); // Sentry will be initialized even in an error caused by loggedInUser failing
    });

  return Promise.all(blockingPromises)
    .then(values => {
      const [loggedInUser, tenant] = values;
      const data = { loggedInUser, tenant };
    })
    .catch(err => {
      appFailure = true;
      err = syncStacktrace(err);
      err.message = "Unable to bootstrap app\n" + err.message;

      // timeout so that the callstack doesn't blame things on spa-bootstrapper when `callback` throws an exception
      const throwError = () => {throw err}

      nonBlockingPromises[0] // Make sure sentry is initialized
        .then(throwError)
        .catch(throwError); // Sentry will be initialized even in an error caused by loggedInUser failing
    });

}
