import * as singleSpa from "single-spa";
import { catchSyncStacktrace } from "auto-trace";
import 'sofe';

import * as isActive from "./child-app-active.helpers.js";
import noAccess from "./no-access.app.js";
import bootstrapSpa from "./bootstrap/spa-bootstrapper.js";

bootstrapSpa().then(() => {
  singleSpa.registerApplication(
    "sme-ui",
    () => SystemJS.import("sme-ui!sofe"),
    isActive.smeUI
  );

  singleSpa.registerApplication(
    "sme-navbar",
    () => SystemJS.import("sme-navbar!sofe"),
    isActive.smeNavbar
  );

  singleSpa.registerApplication("no-access", noAccess, isActive.noAccess);

  singleSpa.start();
}).catch((err) => {
  catchSyncStacktrace(err)
})
