export default function(loggedInUserPromise) {
  return SystemJS.import('sofe').then(sofe =>
    sofe.getAllManifests().then(manifest => {
      return SystemJS.import('error-logging!sofe').then(sentryErrorLogging => {
        if (!loggedInUserPromise) {
          throw new Error(`Cannot initialize sentry without logged in user.`);
        }

        if (!window.nodeBootstrapServerVersion) {
          throw new Error(`Cannot initialize sentry without window.nodeBootstrapServerVersion`);
        }

        sentryErrorLogging.init(`sme-root-config_${window.nodeBootstrapServerVersion}`, null, null, manifest.flat);
        return loggedInUserPromise.then(loggedInUser => sentryErrorLogging.setUser(loggedInUser));
      });
    })
  );
}
