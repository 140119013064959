import { property } from 'lodash';
import { asyncStacktrace } from 'auto-trace';

export default function() {
  return Promise.all([
    SystemJS.import("canopy-urls!sofe"),
    SystemJS.import("fetcher!sofe"),
  ]).then(values => { //eslint-disable-line
    const canopyUrls = values[0].default;
    const fetcher = values[1].default;

    return fetcher(`${canopyUrls.getAPIUrl()}/users/0`)
      .then(response => {
        if (response.ok) {
          return response
            .json()
            .then(property("users"))
            .then(user => (window.loggedInUser = user));
          // .catch is handled in spa-bootstrapper
        } else {
          // This error message will be wrapped below to be more detailed
          throw new Error(`server responded with status ${response.status}`);
        }
      })
      .catch(
        asyncStacktrace(err => {
          // By throwing an error inside of a catch block, this will bubble all the way up
          err.message = `Error retrieving logged in user -- '${err.message}'`;
          throw err;
        })
      );
  });
  // .catch handled in spa-bootstrapper
}
